import { INSTRUMENT_RELATIONSHIPS_SECTION_HASH } from 'app/modules/networkAnalysis/constants';
import {
  U21_NO_VALUE,
  U21Badge,
  U21Chip,
  U21Section,
  U21ShowMoreList,
  U21Table,
  U21TableBodyCellProps,
  U21TableColumn,
  U21TitleCountLabel,
} from 'app/shared/u21-ui/components';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  IconLock,
  IconTags,
  IconLockOpen,
  IconCreditCardOff,
  IconTableExport,
} from '@u21/tabler-icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  BaseObjectType,
  EntityNodeData,
  GraphResult,
  MergedInstrument,
  NetworkAnalysisGroups,
  NodeType,
} from 'app/modules/networkAnalysisRefresh/types';
import { selectEntityToInstrumentRelationshipConfigsByExternalId } from 'app/modules/relationships/selectors';
import { selectHasEditInstrumentActionPermission } from 'app/modules/session/selectors';
import { InstrumentActionStatus } from 'app/modules/instruments/constants';
import { useBulkApplyTagsToInstruments } from 'app/modules/instruments/queries/useBulkApplyTagsOnInstruments';
import { InstrumentActionModal } from 'app/modules/instruments/components/InstrumentActionModal';
import { BulkEditDataLabelsModal } from 'app/modules/dataLabels/BulkEditDataLabelsModal';
import { toggleSidebar } from 'app/modules/sidebar/slice';
import { SidebarComponentTypes } from 'app/modules/sidebar/models';
import {
  NetworkAnalysisContextState,
  useNetworkAnalysisContext,
} from 'app/modules/networkAnalysisRefresh/contexts/NetworkAnalysisContext';
import {
  getLinksColumn,
  isInstrumentNode,
} from 'app/modules/networkAnalysisRefresh/helpers';
import { useHistory, useLocation } from 'react-router';
import { INSTRUMENT_COLUMN_CONFIG } from 'app/modules/instruments/columns';
import { createTableColumnConfig } from 'app/shared/utils/table';
import { selectInstrumentTableConfig } from 'app/shared/CustomConfig/selectors';
import { TableConfigType } from 'app/shared/CustomConfig/models';
import { LinkSectionActions } from 'app/modules/networkAnalysisRefresh/components/LinkSection';
import { selectSidebarInstrumentID } from 'app/modules/sidebar/selectors';
import { useExportInstruments } from 'app/modules/instruments/queries/useExportInstruments';
import { ExportModal } from 'app/modules/fileExports/components/ExportModal';

export interface InstrumentRelationshipLinkTableRow extends MergedInstrument {
  rowId: string;
  relationship_config_external_id: string;
}

export const InstrumentRelationshipsSection = () => {
  const {
    data,
    networkGroups,
    expandedSections,
    getToggleExpandedSection,
    handleLinkSectionMounted,
    baseObjectType,
    fetchedInstruments,
  } = useNetworkAnalysisContext();

  const sectionRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    return handleLinkSectionMounted(
      INSTRUMENT_RELATIONSHIPS_SECTION_HASH,
      sectionRef,
    );
  }, [handleLinkSectionMounted]);

  const linkedRelationshipsLength = useMemo(
    () => Object.keys(networkGroups.instrumentRelationships).length,
    [networkGroups.instrumentRelationships],
  );

  const tableData = useMemo(
    () =>
      data
        ? makeInstrumentRelationshipTableData(
            fetchedInstruments,
            networkGroups,
            data?.graph_result?.nodes,
            baseObjectType,
          )
        : [],
    [data, networkGroups, baseObjectType, fetchedInstruments],
  );

  const relationshipConfigsByExternalId = useSelector(
    selectEntityToInstrumentRelationshipConfigsByExternalId,
  );
  const reduxDispatch = useDispatch();

  const { pathname, search } = useLocation();
  const history = useHistory();

  const instrumentTableConfig = useSelector(selectInstrumentTableConfig);

  const columns: U21TableColumn<InstrumentRelationshipLinkTableRow, any>[] =
    useMemo(() => {
      let cols: U21TableColumn<InstrumentRelationshipLinkTableRow, any>[] = [
        {
          id: 'relationship_config',
          accessor: (row) =>
            relationshipConfigsByExternalId[row.relationship_config_external_id]
              ?.name ?? row.relationship_config_external_id,
          Header: 'Relationship',

          disableSortBy: true,
        },
      ];
      if (data && baseObjectType === BaseObjectType.ENTITY) {
        cols.push({
          id: 'entities',
          Header: 'Related entities',
          Cell: ({
            row,
          }: U21TableBodyCellProps<InstrumentRelationshipLinkTableRow>) => {
            const objects = networkGroups.links[row.node_id]?.objects.reduce<
              EntityNodeData[]
            >((acc, e) => {
              const entity = data?.graph_result?.nodes[e];
              if (
                entity &&
                entity.node_type === NodeType.ENTITY &&
                !entity.is_base_node
              ) {
                acc.push(entity);
              }
              return acc;
            }, []);
            if (!objects?.length) {
              return U21_NO_VALUE;
            }
            return (
              <U21ShowMoreList
                value={objects}
                displayFunc={(value) => {
                  return (
                    <U21Chip
                      onClick={(e) => {
                        e.stopPropagation();
                        reduxDispatch(
                          toggleSidebar({
                            type: SidebarComponentTypes.ENTITY,
                            data: {
                              id: 0,
                              externalId: value.external_id,
                            },
                          }),
                        );
                      }}
                    >
                      {value.display_name}
                    </U21Chip>
                  );
                }}
              />
            );
          },
        });
      } else if (data && baseObjectType === BaseObjectType.INSTRUMENT) {
        cols.push(
          getLinksColumn<InstrumentRelationshipLinkTableRow>(
            data.graph_result.nodes,
            networkGroups,
            history,
            pathname,
            search,
          ),
        );
      }
      cols = [
        ...cols,
        ...createTableColumnConfig<InstrumentRelationshipLinkTableRow>(
          instrumentTableConfig,
          INSTRUMENT_COLUMN_CONFIG as Record<
            string,
            U21TableColumn<InstrumentRelationshipLinkTableRow>
          >,
        ),
      ];
      return cols;
    }, [
      instrumentTableConfig,
      data,
      baseObjectType,
      relationshipConfigsByExternalId,
      networkGroups,
      reduxDispatch,
      history,
      pathname,
      search,
    ]);

  const [selectedInstruments, setSelectedInstruments] = useState<
    InstrumentRelationshipLinkTableRow[]
  >([]);
  const selectedIds = useMemo(
    () => selectedInstruments.map((i) => i.rowId),
    [selectedInstruments],
  );
  const { instrumentUnit21Ids, instrumentExternalIds } = useMemo(
    () =>
      selectedInstruments.reduce<{
        instrumentUnit21Ids: number[];
        instrumentExternalIds: string[];
      }>(
        (acc, i) => {
          acc.instrumentUnit21Ids.push(Number(i.id));
          acc.instrumentExternalIds.push(i.external_id);
          return acc;
        },
        { instrumentUnit21Ids: [], instrumentExternalIds: [] },
      ),
    [selectedInstruments],
  );
  const hasInstrumentActionsPermission = useSelector(
    selectHasEditInstrumentActionPermission,
  );
  const [desiredActionStatus, setDesiredActionStatus] =
    useState<InstrumentActionStatus>();
  const [editDataLabelsModalOpen, setEditDataLabelsModalOpen] = useState(false);
  const {
    isPending: isBulkEditInstrumentsPending,
    mutate: bulkApplyTagsToInstruments,
  } = useBulkApplyTagsToInstruments();

  const sidebarInstrumentID = useSelector(selectSidebarInstrumentID);
  const highlighted = useMemo(() => {
    if (!sidebarInstrumentID) {
      return undefined;
    }
    const row = tableData.find((i) => i.id === sidebarInstrumentID);
    if (!row) {
      return undefined;
    }
    return [row.rowId];
  }, [sidebarInstrumentID, tableData]);

  const [exportModalOpen, setExportModalOpen] = useState(false);
  const { isPending: isExportLoading, mutateAsync: exportInstruments } =
    useExportInstruments();

  return (
    <div ref={sectionRef}>
      <U21Section
        title={
          <U21Badge content={linkedRelationshipsLength}>
            <span>Instrument relationships</span>
          </U21Badge>
        }
        collapsible
        collapsed={!expandedSections.has(INSTRUMENT_RELATIONSHIPS_SECTION_HASH)}
        onToggleCollapse={getToggleExpandedSection(
          INSTRUMENT_RELATIONSHIPS_SECTION_HASH,
        )}
        action={
          <LinkSectionActions
            tableConfigType={TableConfigType.INSTRUMENT_TABLE}
            hash={INSTRUMENT_RELATIONSHIPS_SECTION_HASH}
          />
        }
      >
        <U21Table<InstrumentRelationshipLinkTableRow>
          data={tableData}
          columns={columns}
          getRowID={({ rowId }: InstrumentRelationshipLinkTableRow) => rowId}
          selectable={hasInstrumentActionsPermission}
          selected={selectedIds}
          onRowSelect={(_, rows) => setSelectedInstruments(rows)}
          onRowClick={(_, row) => {
            reduxDispatch(
              toggleSidebar({
                type: SidebarComponentTypes.INSTRUMENT,
                data: { id: row.id },
              }),
            );
          }}
          highlighted={highlighted}
          actions={
            hasInstrumentActionsPermission
              ? [
                  {
                    icon: <IconCreditCardOff />,
                    label: 'Deactivate',
                    onClick: () => {
                      setDesiredActionStatus(
                        InstrumentActionStatus.DEACTIVATED,
                      );
                    },
                    color: 'error',
                  },
                  {
                    icon: <IconLock />,
                    label: 'Lock',
                    onClick: () => {
                      setDesiredActionStatus(InstrumentActionStatus.LOCKED);
                    },
                  },
                  {
                    icon: <IconLockOpen />,
                    label: 'Unlock',
                    onClick: () => {
                      setDesiredActionStatus(InstrumentActionStatus.UNLOCKED);
                    },
                  },
                  {
                    icon: <IconTags />,
                    label: 'Edit Tags',
                    onClick: () => {
                      setEditDataLabelsModalOpen(true);
                    },
                    loading: isBulkEditInstrumentsPending,
                  },
                  {
                    icon: <IconTableExport />,
                    label: 'Export',
                    onClick: () => {
                      setExportModalOpen(true);
                    },
                  },
                ]
              : undefined
          }
        />
      </U21Section>
      <InstrumentActionModal
        open={desiredActionStatus !== undefined}
        onClose={() => {
          setDesiredActionStatus(undefined);
          setSelectedInstruments([]);
        }}
        instrumentIds={instrumentExternalIds}
        desiredActionStatus={desiredActionStatus}
      />
      <BulkEditDataLabelsModal
        editObjectTags={async (
          tagIdsToAdd: (string | number)[],
          tagIdsToRemove: (string | number)[],
        ) => {
          await bulkApplyTagsToInstruments({
            instrument_external_ids: instrumentExternalIds,
            data_labels_to_add: tagIdsToAdd,
            data_labels_to_remove: tagIdsToRemove,
          });
          setSelectedInstruments([]);
        }}
        isEditLoading={isBulkEditInstrumentsPending}
        title="Edit Tags"
        open={editDataLabelsModalOpen}
        handleClose={() => {
          setEditDataLabelsModalOpen(false);
        }}
      />
      <ExportModal
        loading={isExportLoading}
        open={exportModalOpen}
        onClose={() => setExportModalOpen(false)}
        title={
          <U21TitleCountLabel
            count={instrumentExternalIds.length}
            label="instruments"
          >
            Export Instruments
          </U21TitleCountLabel>
        }
        onExport={async (values) => {
          try {
            await exportInstruments({
              export_ids: instrumentUnit21Ids,
              receive_email: values.receive_email,
              use_csv: values.use_csv,
              filters: {},
            });
            setExportModalOpen(false);
            setSelectedInstruments([]);
          } catch {}
        }}
      />
    </div>
  );
};

const makeInstrumentRelationshipTableData = (
  fetchedInstruments: NetworkAnalysisContextState['fetchedInstruments'],
  { instrumentRelationships, objects }: NetworkAnalysisGroups,
  nodes: GraphResult['nodes'],
  baseObjectType: BaseObjectType,
): InstrumentRelationshipLinkTableRow[] =>
  Object.values(instrumentRelationships).reduce<
    InstrumentRelationshipLinkTableRow[]
  >((acc, instrumentRelationshipEdge) => {
    const { id, source, target, label } = instrumentRelationshipEdge;
    if (baseObjectType === BaseObjectType.ENTITY) {
      // related instrument is always the target for entity<>instrument relationships
      const targetInstrument = nodes[target];
      if (isInstrumentNode(targetInstrument)) {
        const fetchedInstrument =
          fetchedInstruments[targetInstrument.external_id];
        if (!fetchedInstrument) {
          return acc;
        }
        acc.push({
          ...fetchedInstrument,
          node_id: targetInstrument.id,
          hashed_id: targetInstrument.hashed_id,
          links: [], // no links for a related instrument on an entity graph
          rowId: id,
          relationship_config_external_id: label,
        });
      }
    } else if (baseObjectType === BaseObjectType.INSTRUMENT) {
      const sourceInstrument = nodes[source];
      const targetInstrument = nodes[target];
      if (
        !isInstrumentNode(sourceInstrument) ||
        !isInstrumentNode(targetInstrument)
      ) {
        return acc;
      }
      // take non-base node
      const instrument = sourceInstrument.is_base_node
        ? objects[target]
        : objects[source];
      const fetchedInstrument = fetchedInstruments[instrument.external_id];
      if (!fetchedInstrument) {
        return acc;
      }
      acc.push({
        ...fetchedInstrument,
        node_id: instrument.id,
        hashed_id: instrument.hashed_id,
        links: instrument.links,
        rowId: id,
        relationship_config_external_id: label,
      });
    }
    return acc;
  }, []);
